import React, { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import ServiceSection from '../components/ServiceSection';
import AboutUsSection from '../components/AboutUsSection';
import ContactSection from '../components/ContactSection';
import Header from '../components/Header';
import Footer from '../components/Footer';
import video from '../assets/fyra_rApp_demo.mp4';

const HomePage: React.FC = () => {
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      const targetElement = document.getElementById(hash);
      if (targetElement) {
        const topPosition = targetElement.offsetTop - 100;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <>
      <Header />
      <Box id="home">
        <video autoPlay loop muted playsInline style={{ width: '100%' }}>
          <source src={video} type="video/mp4" />
        </video>
      </Box>
      <Container maxWidth="md">
        <Box mt={{ xs: 6, sm: 8 }}>
          <Box id="service">
            <ServiceSection />
          </Box>
          <Box id="aboutus">
            <AboutUsSection />
          </Box>
          <Box id="contact">
            <ContactSection />
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default HomePage;
