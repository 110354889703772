import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const AboutUsSection: React.FC = () => {
  const { t } = useTranslation();
  const gmapSrc =
    i18n.language == 'ja'
      ? 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d22735.07439589797!2d139.34383494498127!3d35.65437985229818!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60191fa160d53f5f%3A0x271e60384acab5b0!2z44Gy44G-44KP44KK44OT44Or!5e0!3m2!1sja!2sjp!4v1705743585131!5m2!1sja!2sjp'
      : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.739192833425!2d139.33270337713722!3d35.65879683120394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60191e77fc382ebf%3A0x38a4a8f7b7f38c45!2z44CSMTkyLTAwODUgVG9reW8sIEhhY2hpb2ppLCBOYWthY2jFjSwgOeKIkjEwIOOBsuOBvuOCj-OCiuODk-ODqw!5e0!3m2!1sen!2sjp!4v1707666516993!5m2!1sen!2sjp';
  return (
    <Box mt={4} mb={6}>
      <Typography
        variant="h3"
        align="center"
        sx={{ fontSize: { xs: '7vw', sm: 32 } }}
        gutterBottom
      >
        {t('nav.aboutus')}
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" sx={{ wordBreak: 'auto-phrase' }}>
              {t('aboutus.tr1.th')}
            </TableCell>
            <TableCell>
              <List>
                <ListItem>{t('aboutus.tr1.td')}</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" sx={{ wordBreak: 'auto-phrase' }}>
              {t('aboutus.tr2.th')}
            </TableCell>
            <TableCell>
              <List>
                <ListItem>{t('aboutus.tr2.td')}</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" sx={{ wordBreak: 'auto-phrase' }}>
              {t('aboutus.tr3.th')}
            </TableCell>
            <TableCell>
              <List>
                <ListItem>{t('aboutus.tr3.td')}</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" sx={{ wordBreak: 'auto-phrase' }}>
              {t('aboutus.tr4.th')}
            </TableCell>
            <TableCell>
              <List>
                <ListItem>{t('aboutus.tr4.td')}</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" sx={{ wordBreak: 'auto-phrase' }}>
              {t('aboutus.tr5.th')}
            </TableCell>
            <TableCell>
              <List>
                <ListItem
                  sx={{
                    display: 'list-item',
                    listStyleType: 'disc',
                    listStylePosition: 'inside',
                    padding: '.5em 0 .5em 1.5em',
                  }}
                >
                  {t('aboutus.tr5.td.txt1')}
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item',
                    listStyleType: 'disc',
                    listStylePosition: 'inside',
                    padding: '.5em 0 .5em 1.5em',
                  }}
                >
                  {t('aboutus.tr5.td.txt2')}
                </ListItem>
              </List>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid container spacing={4} mt={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: 0,
              paddingTop: { xs: '61.8%', sm: '61.8%', md: '30.9%' },
            }}
          >
            <Box
              component="iframe"
              src={gmapSrc}
              sx={{
                width: '100%',
                height: '100%',
                border: 0,
                position: 'absolute',
                top: '0',
                left: '0',
              }}
              allowFullScreen
              title="FYRA Access Map"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUsSection;
