import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import { useTranslation } from 'react-i18next';

const ContactSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box mt={4} mb={6}>
      <Typography
        variant="h3"
        align="center"
        sx={{ fontSize: { xs: '7vw', sm: 32 } }}
        gutterBottom
      >
        {t('nav.contact')}
      </Typography>
      <Box textAlign="center" py={5}>
        <Button
          variant="contained"
          color="secondary"
          href="/contact-form"
          size="large"
          startIcon={<MailIcon />}
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '4.5vw', sm: 24 },
            textTransform: 'none',
          }}
        >
          {t('contact.btn.form')}
        </Button>
      </Box>
    </Box>
  );
};

export default ContactSection;
