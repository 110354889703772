import { styled } from '@mui/system';

export const GoldenRatioImage = styled('img')({
  width: '100%',
  height: 'auto',
  aspectRatio: '1.618',
  objectFit: 'cover',
});

export const accessMapStyle = {
  width: '100%',
  paddingBottom: '61.8%',
  position: 'relative',
  overflow: 'hidden',
};
