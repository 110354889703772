import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n/config.ts';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-NNS2XGQ7',
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
);

reportWebVitals();
