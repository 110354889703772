import React from 'react';
import { Box, Typography, Grid, Paper, List, ListItem } from '@mui/material';
import Link from '@mui/material/Link';
import FyraSuiteImage from '../assets/fyra_suite.jpg';
import OranAssistantImage from '../assets/fyra_o_ran_a.jpg';
import { GoldenRatioImage } from '../styles/commonStyles';
import { useTranslation } from 'react-i18next';

const ServiceSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box mt={4} mb={6}>
      <Typography
        variant="h3"
        align="center"
        sx={{ fontSize: { xs: '7vw', sm: 32 } }}
        gutterBottom
      >
        {t('nav.service')}
      </Typography>

      <Grid container spacing={4} mt={0}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec1.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec1.txt1')}</Typography>
          <Typography paragraph>{t('service.sec1.txt2')}</Typography>
          <Typography paragraph>{t('service.sec1.txt3')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={FyraSuiteImage} alt="FYRA Suite" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4} mt={0}>
        <Grid item xs={12} md={6} sx={{ order: { xs: 1, sm: 1, md: 0 } }}>
          <Paper elevation={0}>
            <GoldenRatioImage src={OranAssistantImage} alt="O-RAN Assistant" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ order: { xs: 0, sm: 0, md: 1 } }}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec2.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec2.txt1')}</Typography>
          <Typography paragraph>{t('service.sec2.txt2')}</Typography>
          <List>
            <ListItem
              sx={{
                display: 'list-item',
                listStyleType: 'disc',
                listStylePosition: 'inside',
                padding: '.5em',
              }}
            >
              <Link
                href="https://orandownloadsweb.azurewebsites.net/specifications/"
                target="_blank"
                rel="noopener noreferrer"
              >
                O-RAN ALLIANCE Specifications
              </Link>
            </ListItem>
            <ListItem
              sx={{
                display: 'list-item',
                listStyleType: 'disc',
                listStylePosition: 'inside',
                padding: '.5em',
              }}
            >
              <Link
                href="https://wiki.o-ran-sc.org/display/ORAN"
                target="_blank"
                rel="noopener noreferrer"
              >
                O-RAN Software Community
              </Link>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceSection;
