import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Container } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

const ContactFormPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as
    | { name: string; email: string; title: string; message: string }
    | undefined;
  const [name, setName] = useState(state?.name || '');
  const [email, setEmail] = useState(state?.email || '');
  const [title, setTitle] = useState(state?.title || '');
  const [message, setMessage] = useState(state?.message || '');

  useEffect(() => {
    if (state) {
      setName(state.name);
      setEmail(state.email);
      setTitle(state.title);
      setMessage(state.message);
    }
  }, [state]);

  const handleBack = () => {
    navigate('/');
  };

  const handleConfirm = async (e: React.FormEvent) => {
    e.preventDefault();
    await navigate('/confirm', { state: { name, email, title, message } });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container
        maxWidth="sm"
        sx={{
          flexGrow: 1,
          paddingBottom: '2rem',
          marginTop: { xs: '2rem', sm: '5rem' },
        }}
      >
        <Box
          mt={4}
          mb={6}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h4" gutterBottom>
            {t('contact.ttl')}
          </Typography>
          <Typography mb={2}>{t('contact.note')}</Typography>
          <Box component="form" onSubmit={handleConfirm} sx={{ width: '100%' }}>
            <Box mb={2}>
              <TextField
                fullWidth
                size="small"
                label={t('contact.form.name')}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                size="small"
                label={t('contact.form.email')}
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                size="small"
                label={t('contact.form.subject')}
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                size="small"
                label={t('contact.form.content')}
                variant="outlined"
                multiline
                rows={8}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </Box>
            <Box textAlign="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleBack}
                sx={{
                  textTransform: 'none',
                }}
              >
                {t('contact.btn.back')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  marginLeft: '20px',
                  textTransform: 'none',
                }}
                type="submit"
              >
                {t('contact.btn.confirm')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default ContactFormPage;
